/* latin */
@font-face {
  font-display: swap;
  font-family: "Cardinal";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/cardinalphotoweb-semibold.woff2") format("woff2"),
    url("../../fonts/cardinalphotoweb-semibold.woff") format("woff");
}

/* latin */
@font-face {
  font-display: swap;
  font-family: "TTNorms";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/TTNorms-Regular.woff2") format("woff2"),
    url("../../fonts/TTNorms-Regular.woff") format("woff");
}

/* latin */
@font-face {
  font-display: swap;
  font-family: "TTNorms";
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/TTNorms-Bold.woff2") format("woff2"),
    url("../../fonts/TTNorms-Bold.woff") format("woff");
}

/* fonts */
/* fonts */

/* fonts */
.font-primary {
  font-family: default(font-family);
  font-weight: default(weight);
}

.font-primary-bold {
  font-family: default(font-family);
  font-weight: default(weight, bold);
}

.font-secondary {
  font-family: "Cardinal";
  font-weight: default(weight);
}
