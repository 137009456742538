@use "sass:math";

// Breakpoint
$xxxl-width: 1750px;
$xxl-width: 1550px;
$xl-width: 1320px;
$lg-width: 1200px;
$md-width: 992px;
$sm-width: 768px;
$xs-width: 480px;
$xxs-width: 360px;
// BP max
$xxl-width-max: 1549px;
$xl-width-max: 1319px;
$md-width-max: 991px;
$sm-width-max: 767px;
$xs-width-max: 479px;

$gutter: 3rem;
$gutter-ctn: 1.5rem;

// Grid
$grid-columns: 12;
$column-width: math.div(100, $grid-columns);
// stylelint-disable
@mixin media-breakpoint-up($name, $breakpoints: $breakpoints) {
  $min: breakpoint-min($name, $breakpoints);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

$breakpoints: (
  xxs: (
    break: $xxs-width,
    gutter: 1rem,
    gutter-ctn: 1.5rem,
    container: 100%,
  ),
  xs: (
    break: $xs-width,
    gutter: 1rem,
    gutter-ctn: 1.5rem,
    container: 100%,
    min-width: $xs-width,
  ),
  sm: (
    break: $sm-width,
    gutter: 1rem,
    gutter-ctn: 1.5rem,
    container: 100%,
    min-width: $sm-width,
  ),
  md: (
    break: $md-width,
    gutter: 1.5rem,
    gutter-ctn: 1.5rem,
    container: 96rem,
    min-width: $md-width,
  ),
  lg: (
    break: $lg-width,
    gutter: 1.5rem,
    gutter-ctn: 1.5rem,
    container: 117rem,
    min-width: $lg-width,
  ),
  xl: (
    break: $xl-width,
    gutter: 1.5rem,
    gutter-ctn: 1.5rem,
    container: 129rem,
    min-width: $xl-width,
  ),
  xxl: (
    break: $xxl-width,
    gutter: 1.5rem,
    gutter-ctn: 1.5rem,
    container: 150rem,
    min-width: $xxl-width,
  ),
);

@function break($breakpoint, $break) {
  @return map-get(map-get($breakpoints, $breakpoint), $break);
}

$colors: (
  mono: (
    light: #fff,
    dark: #000,
  ),
  grey: (
    base: #cecece,
    light: #efefef,
  ),
  primary: (
    base: #000,
  ),
  secondary: (
    base: #fff,
  ),
  tertiary: (
    base: #72ce9b,
  ),
  blue: (
    base: #0050b5,
  ),
  promo: (
    base: #ef0e0e,
  ),
  text: (
    base: #000,
    medium: #b6b9bb,
    light: #fff,
  ),
  border: (
    base: #efefef,
    dark: #000,
    light: #fff,
    second: #cecece,
  ),
  alert: (
    warning: orange,
    danger: #ff251c,
    success: #57c378,
    info: lightgray,
  ),
  overlay: (
    base: rgba(0, 0, 0, 0.27),
    light: rgba(0, 0, 0, 0.1),
  ),
  button: (
    base: #000,
    light: #f6f6f6,
    dark: #000,
  ),
  background: (
    base: #efefef,
    light: #fff,
  ),
);

@function color($color, $tone: "base") {
  @return map-get(map-get($colors, $color), $tone);
}

$default: (
  font-size: (
    base: 1.4rem,
  ),
  font-family: (
    base: "TTNorms",
    secondary: "Cardinal",
    icon: "icomoon",
  ),
  weight: (
    base: 400,
    bold: 700,
  ),
  radius: (
    base: 0.3rem,
  ),
  line-height: (
    base: 1.2,
  ),
  shadow: (
    base: 0 0.1rem 0.6rem rgba(0, 0, 0, 0.12),
    secondary: 0.3rem 0.3rem 0.6rem rgba(0, 0, 0, 0.16),
    bottom: 0 5px 5px -5px rgba(0, 0, 0, 0.16),
  ),
  padding: (
    form: 1.5rem,
    form-mobile: 1rem,
  ),
);

@function default($property, $value: "base") {
  @return map-get(map-get($default, $property), $value);
}

$heights: (
  banner: (
    "base": 3.3rem,
    "xs": 4rem,
    "sm": 4rem,
    "md": 6rem,
    "lg": 4rem,
    "stack": 13rem,
  ),
  header: (
    "base": 6rem,
    "xs": 8rem,
    "sm": 8.5rem,
    "md": 25rem,
    "lg": 27rem,
    "nomenu": 13.6rem,
    "stack": 13rem,
  ),
  steps: (
    base: auto,
    sm: 5rem,
    md: 6rem,
  ),
  form: (
    "radio": 2.5rem,
    "checkbox": 2.5rem,
    "input": 4.4rem,
    "select": 5rem,
    "file": 5rem,
    "text": 5rem,
  ),
);

@function height($type, $size: "base") {
  @return map-get(map-get($heights, $type), $size);
}

$icons: (
  arrow-down: "\e901",
  arrow-up: "\e930",
  arrow-right: "\e928",
  arrow-left: "\e92b",
  check: "\e900",
  check-empty: "\e909",
  file: "\e90c",
  filtre_actif: "\e91a",
  filtre_position2: "\e91b",
  eye-open: "\e914",
  plus: "\e90c",
  minus: "\e909",
  caret: "\e927",
  caret-left: "\e929",
  carres_points: "\e917",
  trois_petits_points: "\e924",
  losange_points: "\e91f",
  zoom_plus: "\e926",
  zoom_moins: "\e925",
  checkbox: "\e931",
  checkbox-checked: "\e932",
  radio: "\e933",
  radio-checked: "\e934",
);

@function icon($icon) {
  @return map-get($icons, $icon);
}
