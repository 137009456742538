.breadcrumb {
  color: color(mono, dark);
  font-size: 1.1rem;
  overflow: auto;
  position: relative;
  white-space: nowrap;
  z-index: 200;

  &-item {
    display: inline;
  }

  &-link {
    text-decoration: none;
  }

  @media (min-width: $sm-width) {
    font-size: 1.2rem;
  }
}

.with-background {
  .breadcrumb {
    margin: 0 0 1.5rem 1.5rem;

    @media (min-width: $md-width) {
      @include poa(3rem, null, null, 8rem);
    }
  }
}
