@import "../components/_pagination.scss";

.header-search {
  @include poa(auto, 0, auto, 0);
  background-color: color(secondary);
  cursor: pointer;
  margin-top: -2rem;
  opacity: 0;
  padding: 0 1.5rem 1.5rem;
  transition: visibility 0.3s, opacity 0.3s;
  visibility: hidden;
  z-index: 300;

  @media (min-width: $sm-width) {
    margin-top: -1.6rem;
  }
  @media (min-width: $md-width) {
    margin-top: -1.3rem;
  }
  @media (min-width: $lg-width) {
    margin-top: -3rem;
    padding: 3rem 8rem 8rem;
  }
}

.header-main .search-action {
  @media (max-width: $sm-width-max) {
    .icon {
      font-size: 1.6rem;
    }
  }
}

.search-open {
  .header-search {
    box-shadow: default(shadow, bottom);
    opacity: 1;
    pointer-events: auto;
    transition: visibility 0.3s, opacity 0.3s;
    visibility: visible;
  }
}

.search {
  &-box {
    position: relative;
  }

  &-ctn {
    @include poa(height(header), 0, null, 0);
    @include animated(opacity);
    opacity: 0;
    pointer-events: none;

    @media (min-width: $xs-width) {
      top: height(header, xs);
    }
    @media (min-width: $sm-width) {
      top: height(header, sm);
    }
    @media (min-width: $md-width) {
      opacity: 1;
      pointer-events: auto;
      position: relative;
      top: 0;
    }
    @media (min-width: $lg-width) {
      margin-left: 0;
    }
  }

  &-input {
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid color(primary);
    border-radius: 0;
    border-top: 1px solid color(border);
    font-family: default(font-family);
    font-size: 1.6rem;
    height: auto;
    padding: 3rem 0 1rem;
    width: 100%;

    &:focus,
    .focus-visible {
      box-shadow: none;
    }

    @include placeholder {
      color: color(text);
      font-size: 1.6rem;
    }

    &::-webkit-search-cancel-button {
      display: none;
    }

    @media (min-width: $md-width) {
      height: 5rem;
    }

    &:focus {
      + .search-submit {
        .search-close {
          display: block;
        }
      }
    }
  }

  &-submit {
    @include poa(auto, 0, 0.5rem, null);

    .icon {
      @include rem(22);
      cursor: pointer;
    }

    @media (min-width: $md-width) {
      .icon {
        @include rem(25);
      }
    }
  }

  &-close {
    display: none;
  }
}

/* Liste résultats Ajax */
.ac_results {
  background: color(mono, light);
  display: block;
  left: 0 !important;
  padding: 0;
  position: absolute !important;
  right: 0;
  top: 145px !important;
  width: auto !important;
  z-index: 10;

  li {
    font-size: 14px;
    padding: 20px 15px;
  }
}

.search-open {
  .search-ctn {
    opacity: 1;
    pointer-events: auto;
  }

  .breadcrumb {
    z-index: 100;
  }
}
