@use "sass:math";

// stylelint-disable
.ctn {
  margin-left: auto;
  margin-right: auto;
}

.ctn-fluid,
.ctn {
  // padding de base (first breakpoint)
  padding-left: break(xxs, gutter-ctn);
  padding-right: break(xxs, gutter-ctn);
}

.flex,
.col,
.row {
  display: flex;
  box-sizing: border-box;
  flex: 0 0 auto;
  flex-wrap: wrap;
  justify-content: space-between;
}

.row {
  flex-direction: row;
  // margin de base (first breakpoint)
  margin-left: (break(xxs, gutter)) * -1;
  margin-right: (break(xxs, gutter)) * -1;
}

.row.reverse {
  flex-direction: row-reverse;
}

.col {
  flex-direction: column;
}

.col.reverse {
  flex-direction: column-reverse;
}

.wrap {
  flex-wrap: wrap;
}

.nowrap {
  flex-wrap: nowrap;
}

// gouttière et marges de base
.xxs {
  padding-left: break(xxs, gutter);
  padding-right: break(xxs, gutter);
}

@for $i from 0 through $grid-columns {
  .xxs#{$i} {
    padding-left: break(xxs, gutter);
    padding-right: break(xxs, gutter);
  }
}

@mixin flex-styles($grid-class, $data) {
  $container: map-get($data, container);

  .ctn {
    width: $container;
  }

  .#{$grid-class} {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-#{$grid-class},
  .flex-#{$grid-class} {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @if ($grid-class != "xxs12") {
    .row-#{$grid-class} {
      margin-left: -#{math.div($gutter, 2)};
      margin-right: -#{math.div($gutter, 2)};
    }
  }

  .col-#{$grid-class} {
    flex-direction: column;
  }

  @for $i from 0 through $grid-columns {
    .#{$grid-class}#{$i},
    .offset-#{$grid-class}#{$i} {
      @extend %flex-global-styles-#{$grid-class};
    }

    @if ($i > 0) {
      .#{$grid-class}#{$i} {
        flex-basis: #{$column-width * $i + "%"};
        max-width: #{$column-width * $i + "%"};
      }
    }

    .offset-#{$grid-class}#{$i} {
      margin-left: #{$column-width * $i + "%"};
    }
  }

  .start-#{$grid-class} {
    justify-content: flex-start;
    text-align: start;
  }

  .center-#{$grid-class} {
    justify-content: center;
    text-align: center;
  }

  .end-#{$grid-class} {
    justify-content: flex-end;
    text-align: end;
  }

  .around-#{$grid-class} {
    justify-content: space-around;
  }

  .between-#{$grid-class} {
    justify-content: space-between;
  }

  .top-#{$grid-class} {
    align-items: flex-start;
    align-self: start;
  }

  .middle-#{$grid-class} {
    align-items: center;
    align-self: center;
  }

  .bottom-#{$grid-class} {
    align-items: flex-end;
    align-self: end;
  }

  .baseline-#{$grid-class} {
    align-items: baseline;
    align-self: baseline;
  }

  .first-#{$grid-class} {
    order: -1;
  }

  .last-#{$grid-class} {
    order: 1;
  }

  .show-#{$grid-class} {
    display: block !important;
  }

  .nowrap-#{$grid-class} {
    flex-wrap: nowrap;
  }

  .show-ib-#{$grid-class} {
    display: inline-block !important;
  }
  .show-flex-#{$grid-class} {
    display: flex !important;
  }

  %flex-global-styles-#{$grid-class} {
    box-sizing: border-box;
  }

  .hide-#{$grid-class},
  .hidden-#{$grid-class} {
    display: none !important;
  }
}

@mixin gutter-styles($data) {
  $gutter: map-get($data, gutter);
  $gutter-ctn: map-get($data, gutter-ctn);
  $break: map-get($data, break);

  @media (min-width: $break) {
    .ctn-fluid,
    .ctn {
      padding-left: #{$gutter-ctn};
      padding-right: #{$gutter-ctn};
    }

    .row,
    .col {
      margin-left: -#{$gutter};
      margin-right: -#{$gutter};
    }

    .xxs {
      padding-left: $gutter;
      padding-right: $gutter;
    }

    @for $i from 0 through $grid-columns {
      .xxs#{$i} {
        padding-left: $gutter;
        padding-right: $gutter;
      }
    }
  }
}

@each $grid-class, $data in $breakpoints {
  $min-width: map-get($data, min-width);

  // styles de la grille
  @if ($min-width) {
    @media (min-width: $min-width) {
      @include flex-styles($grid-class, $data);
    }
  } @else {
    @include flex-styles($grid-class, $data);
  }
}

// gouttières et marges pour tous les breakpoints au dessus de xxs
@each $every, $data in $breakpoints {
  @include gutter-styles($data);
}

.nogrow {
  flex-basis: auto;
  flex-grow: 0;
}

.grow {
  flex-basis: 0;
  flex-grow: 1;
}

@for $i from 1 through 5 {
  .grow#{$i} {
    flex-basis: auto;
    flex-grow: #{$i};
  }
}
