.body-regular {
  font-size: 1.6rem;
  line-height: 2.2rem;

  @media (min-width: $md-width) {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
}

.body-regular2 {
  font-size: 1.4rem;
  line-height: 2rem;

  @media (min-width: $md-width) {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

.body-small {
  font-size: 1.2rem;
  line-height: 2rem;

  @media (min-width: $md-width) {
    font-size: 1.4rem;
    line-height: 2.6rem;
  }
}

.body-small2 {
  font-size: 1.1rem;
  line-height: 1.4rem;

  @media (min-width: $md-width) {
    font-size: 1.2rem;
  }
}

.wysiwyg,
.p-regular {
  font-size: 1.4rem;
  line-height: 2.4rem;

  @media (min-width: $md-width) {
    font-size: 1.6rem;
    line-height: 3rem;
  }
}

.p-regular2 {
  font-size: 1.2rem;
  line-height: 2rem;

  @media (min-width: $md-width) {
    font-size: 1.4rem;
    line-height: 2.6rem;
  }
}

strong {
  font-weight: default(weight, bold);
}

sup {
  display: inline-block;
  font-size: 0.65em;
  margin: -0.15em 0 0 -0.3em;
  vertical-align: top;
}
