input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: #fff !important;
}

.btn {
  background: color(button);
  border: 1px solid color(button);
  color: color(mono, light);
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 1.4rem;
  letter-spacing: 0.13rem;
  line-height: normal;
  padding: 0.6rem 1rem;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  z-index: 3;

  @media (min-width: $md-width) {
    font-size: 1.6rem;
  }

  &:focus,
  &:active {
    border-radius: 0;
    // box-shadow: default(shadow, base);
    outline: 0 !important;
  }

  &[disabled],
  &[disabled="disabled"] {
    background-color: color(grey) !important;
    border-color: color(grey) !important;
    cursor: not-allowed;
  }

  @media (min-width: $md-width) {
    &:hover {
      text-decoration: none;
    }
  }

  // level of importance
  &-primary {
    background: color(primary);
    border-color: color(primary);
    color: color(mono, light);
    padding: 1.5rem 1rem;

    @media (min-width: $md-width) {
      padding: 1.5rem 3rem;
    }
  }

  &-secondary {
    background: color(mono, light);
    border-color: color(primary);
    color: color(primary);

    @media (min-width: $md-width) {
      &:hover {
        background-color: color(primary, dark);
        color: color(mono, light);
      }
    }
  }

  &-ecommerce {
    background: color(alert, success);
    border-color: color(alert, success);

    @media (min-width: $md-width) {
      &:hover {
        background-color: color(primary);
        border-color: color(primary);
        color: color(mono, light);
      }
    }
  }

  &-mea {
    background-color: color(mono, light);
    border-color: color(mono, light);
    color: color(primary);

    @media (min-width: $md-width) {
      &:hover {
        background-color: color(primary);
        border-color: color(primary);
        color: color(mono, light);
      }
    }
  }

  &-unstyled {
    background: transparent;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    font-family: default(font-family);
    font-size: 1.4rem;
    line-height: 1;

    @media (min-width: $md-width) {
      font-size: 1.6rem;
    }
  }

  &-link {
    border-bottom: 1px solid #000;
    font-size: 1.4rem;
    padding: 1rem;
    transition: 0.3s;

    &.active {
      color: color(primary);
    }

    @media (min-width: $md-width) {
      font-size: 1.6rem;

      &:hover {
        background: color(primary);
        color: color(secondary);
      }
    }
  }

  &-linkmore {
    border: 1px solid #000;
    font-size: 1.4rem;
    padding: 1rem 10rem;
    transition: 0.3s;

    &.active {
      color: color(primary);
    }

    @media (min-width: $md-width) {
      font-size: 1.6rem;

      &:hover {
        background: color(primary);
        color: color(secondary);
      }
    }
  }

  &-fw {
    text-align: center;
    width: 100%;
  }

  &-lower {
    text-transform: none;
  }

  &-small {
    font-size: 1.2rem;
    height: auto;
    letter-spacing: 0.15rem;
    padding: 8px 13px;

    @media (min-width: $md-width) {
      font-size: 1.4rem;
      padding: 11px 13px;
    }
  }

  &-big {
    font-size: 1.4rem;
    height: auto;
    letter-spacing: 0.15rem;
    padding: 15px;

    @media (min-width: $md-width) {
      font-size: 1.6rem;
      padding: 17px 15px;
    }
  }

  /* with icons */
  &.has-icon-right,
  &.has-icon-left {
    align-items: center;
    display: flex;

    span {
      font-size: 1.1rem;
      letter-spacing: 0.15rem;
      text-align: left;
    }

    .icon {
      font-size: 100%;
    }

    &-close {
      .icon,
      span {
        vertical-align: middle;
      }

      .icon {
        font-size: 100%;
      }

      span {
        margin: 0 0.5rem;
      }
    }
  }

  &.has-icon-right {
    padding-right: 1.3rem;

    .icon {
      margin-left: 1.5rem;
      order: 2;
    }

    &-close {
      width: 100%;
    }
  }

  &.has-icon-left {
    padding-left: 2.3rem;

    span {
      margin-left: 1.5rem;
      order: 2;
      padding-top: 3px;
    }

    &-close {
      width: 100%;
    }
  }
}

.link {
  font-size: 1.2rem;
  line-height: 3rem;
  text-decoration: underline;

  &:hover {
    font-weight: default(weight, bold);
  }

  @media (min-width: $md-width) {
    font-size: 1.4rem;
  }
}

.link-rounded {
  @include size(2.5rem);
  background: color(primary);
  border-radius: 5rem;
  display: block;
  position: relative;

  .icon {
    color: color(text, light);

    @extend %full-center;
  }

  &:active {
    background-color: color(primary, dark);
  }

  @media (min-width: $md-width) {
    @include size(3.5rem);
    .icon {
      font-size: 2.2rem;
      line-height: 2.2rem;
    }
    &:hover {
      background-color: color(primary, dark);
    }
  }
}

/*** product qty ****/
.product-qty {
  text-align: center;

  &-ctn {
    // border: 1px solid color(primary);
    // border-radius: 5rem;
    color: color(primary);
    margin-bottom: 10px;
    position: relative;

    &.cart-qty {
      .product-qty-input {
        border: 1px solid #efefef;
        font-size: 1.4rem;
        font-weight: default(weight, medium);
        height: 4rem;
      }

      .product-qty-down,
      .product-qty-up {
        border: 1px solid #efefef;
        line-height: 4rem;
      }
    }
  }

  &-down,
  &-up {
    @include poa($top: 0, $bottom: 0);
    background-color: color(mono, light);
    border: 1px solid color(border);
    color: color(primary);
    cursor: pointer;
    font-size: 0;
    line-height: 4.1rem;
    outline: 0;
    transition: 0.3s;
    width: 40px;
    z-index: 2;

    &:focus {
      //   box-shadow: default(shadow, base);
      outline: 0;
    }

    &:before {
      font-family: icomoon;
      font-size: 1rem;
    }

    @media (min-width: $md-width) {
      &:before {
        font-size: 1.3rem;
      }
    }

    &.style2 {
      align-items: center;
      border: 0;
      display: flex;
      height: 4.8rem;
      justify-content: center;
      width: 35px;

      &:before {
        font-size: 1rem;
      }

      @media (min-width: $md-width) {
        height: 5.5rem;
        line-height: 5.5rem;
        &:hover {
          color: color(primary, dark);
        }
      }
    }
  }

  &-down {
    font-weight: default(weight, bold);
    left: 0;

    &:before {
      content: icon(minus);
    }
  }

  &-up {
    font-weight: default(weight, bold);
    right: 0;

    &:before {
      content: icon(plus);
    }
  }

  &-btn,
  &-input {
    // font-weight: default(weight, bold);
  }

  &-input {
    -moz-appearance: textfield;
    border: 0;
    border-bottom: 1px solid color(border);
    border-radius: 5rem;
    border-top: 1px solid color(border);
    color: color(primary);
    font-family: default(font-family);
    font-size: 1.4rem;
    height: 4.1rem;
    padding: 0 3.5rem;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 1;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus {
      outline: 0;
    }

    &.style2 {
      border: 0;
      font-size: 1.6rem;
      font-weight: default(weight, bold);
      height: 4.8rem;

      @media (min-width: $md-width) {
        height: 5.5rem;
      }
    }
  }
}

/**** LINK CATEGORY ****/
.cat {
  &-list {
    &-ctn {
      overflow-y: auto;
    }
  }

  &-link {
    border-bottom: 1px solid transparent;
    transition: 0.3s;
  }

  &-item {
    &:first-of-type {
      padding-left: 0;
    }

    &:hover,
    &.active {
      .cat-link {
        border-color: color(border, dark);
      }
    }
  }
}

/********** sticky mobile btn **********/
.sticky-mobile-btn {
  @include position(fixed, null, 0, 0, 0);
  background-color: color(mono, light);
  box-shadow: default(shadow, top);
  display: block;
  margin-bottom: 0;
  padding: 1.5rem;
  transition: 0.3s;
  z-index: 100;
}

.absolute-mobile-btn {
  @extend .sticky-mobile-btn;
  position: absolute;
}

.add-to-cart {
  font-size: 1rem;
  letter-spacing: 1px;
  width: 100%;

  &:before {
    content: "\e90a";
    font-family: "icomoon";
    font-size: 1.2rem;
    margin-right: 6px;
  }

  &.add-to-quote:before {
    content: "\e912";
  }
}
