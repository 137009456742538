// génération de class utilitaires de couleur
// stylelint-disable-next-line scss/dollar-variable-default

$colors-bg: (
  "black": color(mono, dark),
  "white": color(mono, light),
  "grey": color(grey, base),
  "grey-light": color(grey, light),
  "primary": color(primary, base),
  "blue": color(blue, base),
  "secondary": color(secondary, base),
  "tertiary": color(tertiary, base),
);

$colors-txt: (
  "dark": color(mono, dark),
  "white": color(mono, light),
  "primary": color(primary, base),
  "secondary": color(secondary, base),
  "danger": color(alert, danger),
  "promo": color(promo, base),
  "success": color(alert, success),
);

@each $color, $value in $colors-bg {
  @include bg-color(".bg-#{$color}", $value, true);
}
@each $color, $value in $colors-txt {
  @include text-color(".color-#{$color}", $value, true);
}
