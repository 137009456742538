/* contours */
.shadow {
  box-shadow: default(shadow);
}

.shadow-secondary {
  border: 1px solid #efefef;
  box-shadow: default(shadow, secondary);
}

.overlay {
  background: color(overlay, base);
}

.radius {
  border-radius: default(radius);
}

.focus-visible {
  box-shadow: default(shadow, base);
  outline: 0;
}

.upper {
  text-transform: uppercase;
}

.h-9 {
  height: 9rem;
}

/* overflow mobile */
.overflow-mobile {
  @media (max-width: $sm-width) {
    overflow: auto;

    > div,
    > ul {
      flex-wrap: nowrap;
    }
  }
}

/* row without gutter */
.row-without-gutter {
  margin: 0;

  > .xxs6,
  > .xxs12 {
    padding-left: 0;
    padding-right: 0;
  }
}

.reverse-gutter-mobile {
  @media (max-width: $sm-width) {
    margin-left: -(break(xxs, gutter-ctn));
    margin-right: -(break(xxs, gutter-ctn));
  }
}

/* grid & space */
@media (min-width: $lg-width) {
  .ctn-fluid.padded {
    padding-left: 7%;
    padding-right: 7%;
  }
}

.strong {
  font-weight: default(weight, bold);
}

.barre {
  text-decoration: line-through;
}

.block {
  margin-bottom: 3rem;
}

.ib {
  display: inline-block;
}

.fh {
  height: 100%;
}

.fw {
  width: 100%;
}

.nw {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.img-responsive {
  max-width: 100%;
}

.cover {
  background-size: cover;
}

.valign-middle {
  > * {
    display: inline-block;
    float: none;
    vertical-align: middle;
  }
}

.medium-ctn {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 107rem;
}

.small-ctn {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 65rem;
}

.xs-ctn {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 45rem;
}

// hover shadow
.hover {
  &-shadow {
    &.active {
      box-shadow: default(shadow);
    }

    @media (min-width: $md-width) {
      &:hover {
        box-shadow: default(shadow);
      }
    }
  }

  &-shadow2 {
    &.active {
      box-shadow: default(shadow, secondary);
    }

    @media (min-width: $md-width) {
      &:hover {
        box-shadow: default(shadow, secondary);
      }
    }
  }
}

.unvisible {
  display: none;
}

/* styles */
.underline {
  text-decoration: underline;
}

.hidden {
  display: none;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

/* links */
.link {
  &:hover {
    text-decoration: underline;
  }
}

/* video iframe */
.video-ctn {
  margin-bottom: 15px;
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    border: none;
    height: 100%;
    left: 0;
    margin: 0 0 0 0;
    overflow: hidden;
    padding: 0 0 0 0;
    position: absolute;
    top: 0%;
    width: 100%;
  }
}

.mention {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;

  @media (min-width: $md-width) {
    font-size: 1.4rem;
  }
}

.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.hover-underline {
  @media (min-width: $md-width) {
    &:hover {
      text-decoration: underline;
    }
  }

  &.active {
    text-decoration: underline;
  }
}
