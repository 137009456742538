.header-stack {
  .advmenu-action {
    opacity: 1;
  }
}

@media (min-width: $md-width) {
  .menu-open-desktop {
    overflow: hidden;
  }
}

// displayMenu
.advmenu {
  background: color(secondary);
  bottom: 0;
  color: color(text, light);
  height: 100%;
  left: -100%;
  order: 4;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  @media (min-width: $md-width) {
    background: transparent;
    border-top: 0;
    display: flex;
    left: 0;
    overflow: visible;
    padding: 0;
    position: initial;
    text-align: center;
    top: 3px;
    z-index: 1;

    .header-mobile {
      display: none;
    }
  }

  .bold {
    font-weight: default(weight, bold);
  }

  .container-menu {
    width: 100%;

    @media (min-width: $md-width) {
      max-width: 870px;
    }
  }

  &:hover {
    .advmenu-link {
      color: color(primary, dark);
    }
  }

  // Switch Menu
  &-action {
    display: flex;

    .icon {
      color: color(secondary);
      font-size: 10px;
    }

    &-label {
      display: none;
    }
  }

  &-open {
    overflow: hidden;

    .breadcrumb {
      z-index: 1;
    }

    .advmenu,
    .header-top-right {
      left: 0;
    }

    .overtop,
    .header-top {
      position: relative;
      z-index: 105;
    }

    // hide axeptio & elfsight contact popup
    .axeptio_mount,
    [class^="elfsight-app"] {
      display: none;
    }
  }

  .footer-links {
    background-color: color(background);
    max-width: 100%;

    @media (min-width: $md-width) {
      display: none;
    }
  }

  .advblocklink {
    &-inner {
      margin: 0 auto;
      max-width: 100%;
      width: 370px;
    }
  }

  &-universe-link {
    @include position(fixed, null, 1.5rem, 1.5rem, 1.5rem);
    display: none;
    height: 4rem;
    margin: 0 auto;
    max-width: 100%;
    padding-bottom: 8px;

    @media (min-width: $xs-width) {
      width: 400px;
    }
    @media (min-width: $md-width) {
      display: inline-block;
      margin-top: 3rem;
      position: static;
      width: auto;
    }
  }

  &-content {
    justify-content: center;
    margin: 0;
    padding: 0 3rem 0;
    position: relative;
    text-align: left;
    top: 0;

    @media (min-width: $sm-width) {
      top: 0;
    }
    @media (min-width: $md-width) {
      justify-content: space-between;
      padding: 0;
      position: static;
      text-align: center;
    }
    @media (min-width: $lg-width) {
      margin: 0 -1.5rem;
    }
  }

  &-root-link {
    color: color(primary);
    font-size: 1.6rem;
    letter-spacing: 1.2px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    z-index: 2;

    @media (min-width: $md-width) {
      display: block;
      padding-bottom: 6px;

      &:not(.close-tab-title)::before {
        content: attr(title);
        display: block;
        font-weight: default(weight, bold);
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }
    }

    &.close-tab-title::before {
      content: icon(arrow-left);
      font-family: "icomoon";
      font-size: 20px;
      left: 0;
      position: absolute;
      top: 0;
    }

    &::after {
      content: "\e901";
      font-family: "icomoon";
      font-size: 20px;
      transform: rotateZ(-90deg);

      @media (min-width: $md-width) {
        content: none;
      }
    }

    @media (min-width: $md-width) {
      font-size: 1.6rem;
      .has-bg-header & {
        color: color(secondary);
      }
    }
    @media (min-width: $lg-width) {
      font-size: 1.6rem;
    }
  }

  &-root-item {
    border-bottom: 1px solid #efefef;
    padding-bottom: 5px;
    padding-top: 5px;
    width: 100%;

    @media (min-width: $md-width) {
      border: 0;
      padding: 0;
      width: auto;
    }

    .lvlactive.advsubmenu {
      opacity: 1;
      pointer-events: auto;
      transition: opacity 0.3s, visibility 0.1s;
      visibility: visible;

      .menu-open-desktop & {
        transition: opacity 0s, visibility 0s;
      }
    }

    &-content {
      margin: 0 auto;
      max-width: 100%;
      padding: 1.5rem 0;
      position: relative;

      @media (min-width: $md-width) {
        padding: 1.5rem 0 2rem;
        width: auto;
      }
      @media (min-width: $lg-width) {
        padding: 2rem 1.5rem 4rem;

        &::after {
          background-size: auto;
        }
      }
    }

    &:hover {
      .advmenu-root-item-content {
        &::after {
          bottom: 6px;
          left: 0;
          opacity: 0.2;
        }
      }

      .advmenu-root-link {
        @media (min-width: $md-width) {
          color: color(primary);
          font-weight: default(weight, bold);
        }
      }
    }

    &.open {
      .advsubmenu {
        left: 0;
        overflow: visible;
        transition: 0.3s;

        .advmenu-universe-link {
          display: inline-block;
        }
      }

      .advmenu-root-link:after {
        content: "";

        @media (min-width: $md-width) {
          content: none;
        }
      }
    }
  }

  &-cta {
    margin-top: 3rem;

    @media (min-width: $md-width) {
      margin-top: 0;
    }

    li {
      margin-bottom: 1rem;
    }

    .btn {
      text-align: center;
      width: 100%;
    }
  }
}

.see-all {
  &-link {
    font-size: 1.8rem;
    font-weight: default(weight, bold);

    @media (min-width: $md-width) {
      display: none;
    }

    &:hover {
      color: color(text);
    }
  }
}

.header-stack {
  @media (min-width: $md-width) {
    .advsubmenu {
      top: 6.5rem;
    }
  }

  .advmenu-root-link {
    color: color(primary);
  }
}

.advsubmenu {
  background: color(mono, light);
  bottom: 0;
  left: -100%;
  overflow: hidden;
  position: absolute;
  text-align: left;
  top: 0;
  width: 100%;
  z-index: 3;

  @media (min-width: $md-width) {
    opacity: 0;
    overflow: visible;
    pointer-events: none;
    text-align: left;
    transition: visibility 0.3s, opacity 0.2s;
    visibility: hidden;
    z-index: 1;
    .menu-open-desktop & {
      transition: opacity 0s, visibility 0s;
    }

    @include poa(height(header, md), 0, 0, 0);
  }
  @media (min-width: $lg-width) {
    top: height(header, lg);
  }

  &-list {
    @media (min-width: $md-width) {
      max-height: calc(100vh - #{height(header, md)});
      overflow: auto;
      padding-top: 5vh;
    }
    @media (min-width: $lg-width) {
      max-height: calc(100vh - #{height(header, lg)});
    }
    @media (min-width: $xxxl-width) {
      padding-top: 2rem;
    }
  }

  &-inner {
    @media (min-width: $md-width) {
      .advsubmenu-bg {
        @include poa(0, 0, 0, 33.33%);
        background-position: center right;
        background-repeat: no-repeat;
        background-size: cover;
        height: calc(100vh - 270px);
        overflow: auto;
      }
      .close-submenu-desktop {
        @include poa(15px, 15px, auto, auto);
        cursor: pointer;

        .icon {
          color: color(mono, light);
          text-shadow: 1px 0 2px rgb(0 0 0);
        }
      }
    }
    @media (min-width: $lg-width) {
      .advsubmenu-bg {
        left: 25%;
      }
    }
  }

  &-row {
    margin: 0 auto;
    max-width: 100%;
    padding: 0;
    position: initial;

    @media (min-width: $md-width) {
      margin: 0;
      max-width: none;
      padding: 0 0 3rem 0;
      width: auto;
    }

    .container-submenu {
      background: #fff;
      border-top: 1px solid #f3f3f3;
      height: 100%;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      width: 100%;

      @media (min-width: $md-width) {
        height: 90vw;
        padding-left: 3vw;
        width: 33.33%;
      }
      @media (min-width: $lg-width) {
        width: 25%;
      }
      @media (min-width: $xxxl-width) {
        padding-left: 8rem;
      }
    }
  }

  &-item {
    &:hover,
    &.active {
      .advsubmenu-img-desk {
        transform: scale(1.05);
      }
    }

    &.active-link .advsubmenu-title:after {
      content: "\e909";

      @media (min-width: $md-width) {
        content: "";
      }
    }
  }

  &-title {
    cursor: pointer;
    padding-bottom: 20px;
    padding-top: 6px;
    transition: 0.3s;
    width: 100%;

    &:after {
      content: "\e90c";
      float: right;
      font-family: "icomoon";
    }

    @media (min-width: $md-width) {
      border-bottom: 2px solid transparent;
      padding-bottom: 5px;
      width: auto;
      &:after {
        content: "";
      }
    }
  }

  &-link {
    border-radius: default(radius);
    border-top: 1px solid #efefef;
    color: color(primary);
    font-size: 1.6rem;
    font-weight: default(weight, bold);
    padding: 1.5rem 0 0 0;

    @media (min-width: $md-width) {
      border-top: 0;
      margin-bottom: 1.8rem;
      padding: 0;
    }
    @media (min-width: $xxl-width) {
      font-size: 1.8rem;
      margin-bottom: 2rem;
    }
    @media (max-width: $md-width-max) {
      .advsubmenu-title {
        display: flex;

        > span {
          flex-basis: 92%;
          max-width: 92%;
        }
      }
    }

    .advsubmenu-title:after {
      content: "\e90c";
      float: right;
      font-family: "icomoon";
      font-size: 1.3rem;

      @media (min-width: $md-width) {
        content: "";
      }
    }

    &.menu-img {
      @media (min-width: $md-width) {
        text-align: center;

        .advsubmenu-title {
          display: block;
          margin: 2rem auto 4rem;
        }
      }
    }
  }

  @media (min-width: $md-width) {
    .active-link .advsubmenu-title,
    .advsubmenu-title:hover {
      border-bottom: 2px solid color(border, dark);
      padding-bottom: 5px;
    }
  }

  &-img-desk {
    border-radius: default(radius);
    transition: 1.3s;

    &-ctn {
      border-radius: default(radius);
      line-height: 0;
      overflow: hidden;
    }
  }

  &-child {
    &-menu {
      // padding: 0 ($gutter / 2) 4rem;
      display: none;

      @media (min-width: $md-width) {
        background: #f3f3f3;
        left: 33.33%;
        max-height: 100%;
        min-height: 63vh;
        overflow: auto;
        padding-right: 0;
        position: absolute;
        top: 0;
        width: 33.33%;
      }
      @media (min-width: $lg-width) {
        left: 25%;
        width: 25%;
      }

      &.active-sub {
        display: block;

        @media (min-width: $md-width) {
          padding-left: 3vw;
          padding-top: 5vh;
        }
        @media (min-width: $xxxl-width) {
          padding-left: 8rem;
          padding-top: 2rem;
        }
      }
    }

    &-link {
      color: color(primary);
      display: inline-block;
      font-size: 1.6rem;
      margin-bottom: 2rem;
      text-decoration: none;
      width: 100%;

      @media (min-width: $xxxl-width) {
        font-size: 1.8rem;
        margin-bottom: 2.5rem;
      }

      &:hover {
        font-weight: default(weight, bold);
        text-decoration: none;
      }
    }
  }

  .ctn {
    @media (max-width: $md-width) {
      padding: 0;
    }
  }

  .close-tab {
    background: #f3f3f3;

    @media (min-width: $md-width) {
      display: none;
    }

    &-link {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      padding: 1rem 1.5rem;

      @media (min-width: $md-width) {
        padding: 0;
        width: 100%;
      }
    }

    .icon {
      font-size: 1.1rem;
      margin-right: 1rem;
    }
  }
}

.menu-bottom-mobile {
  background: #f3f3f3;
  margin: 2rem;
  padding: 3rem 2rem;

  @media (min-width: $md-width) {
    display: none;
  }

  .menu-mobile-icon {
    color: color(primary);

    &:nth-child(n + 2) {
      padding-top: 30px;
    }

    a {
      align-items: baseline;
      display: flex;
    }

    span:nth-child(2) {
      padding-left: 10px;
    }
  }

  .listing-country {
    .listing-toggle {
      left: auto;
      right: 0;
      top: 23px;
    }
  }
}

.advsubmenu-img {
  display: none;
  height: 65vw;
  left: 0;
  position: absolute;
  top: 0;
  z-index: -1;
}

.active-link .advsubmenu-img {
  display: block;
}
