/* listing */
.listing {
  &-parent {
    position: relative;
    .icon {
      font-size: 10px;
      margin-top: 3px;
    }
    &.open {
      .listing-toggle {
        display: block;

        &-target {
          .icon::before {
            content: icon(arrow-up);
          }
        }
      }
    }
  }

  &-toggle {
    @include poa(100%, -50%, null, 0);
    border: 1px solid color(border);
    box-shadow: default(shadow);
    display: none;
    margin-top: -0.1rem;
    width: 200px;
    z-index: 2;

    .listing-link {
      padding: 0.5rem 1.5rem;
      transition: 0.3s;
    }
  }

  .listing-link {
    display: block;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  @media (min-width: $md-width) {
    &-hover {
      right: 0;
    }

    &-parent:hover {
      .listing-hover {
        display: block;
      }

      .listing-toggle-target {
        .icon::before {
          content: icon(arrow-up);
        }
      }
    }

    &-link:hover {
      background-color: color(background);
    }
  }
}
