@use "sass:map";
// génération de class utilitaires de spacing
// stylelint-disable-next-line scss/dollar-variable-default
$spacer: 2rem;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: (
    $spacer * 0.75,
  ),
  4: (
    $spacer * 1,
  ),
  5: (
    $spacer * 1.5,
  ),
  6: (
    $spacer * 2.5,
  ),
  7: (
    $spacer * 3,
  ),
  8: (
    $spacer * 4,
  ),
  9: (
    $spacer * 5,
  ),
  10: (
    $spacer * 7.5,
  ),
);

@function spacers($property, $value: "base") {
  @return map-get(map-get($spacers, $property), $value);
}

// stylelint-disable declaration-no-important

// Margin and Padding

@each $breakpoint in map-keys($breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length;
        }
        .#{$abbrev}t#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top: $length;
        }
        .#{$abbrev}r#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-right: $length;
        }
        .#{$abbrev}b#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-bottom: $length;
        }
        .#{$abbrev}l#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-left: $length;
        }
      }
    }

    // Negative margins (e.g., where `.mb-n1` is negative version of `.mb-1`)
    @each $size, $length in $spacers {
      @if $size != 0 {
        .m#{$infix}-n#{$size} {
          margin: -$length;
        }
        .mt#{$infix}-n#{$size},
        .my#{$infix}-n#{$size} {
          margin-top: -$length;
        }
        .mr#{$infix}-n#{$size},
        .mx#{$infix}-n#{$size} {
          margin-right: -$length;
        }
        .mb#{$infix}-n#{$size},
        .my#{$infix}-n#{$size} {
          margin-bottom: -$length;
        }
        .ml#{$infix}-n#{$size},
        .mx#{$infix}-n#{$size} {
          margin-left: -$length;
        }
      }
    }

    // Some special margin utils
    .m#{$infix}-auto {
      margin: auto;
    }
    .mt#{$infix}-auto,
    .my#{$infix}-auto {
      margin-top: auto;
    }
    .mr#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-right: auto;
    }
    .mb#{$infix}-auto,
    .my#{$infix}-auto {
      margin-bottom: auto;
    }
    .ml#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-left: auto;
    }
  }
}
