/* width */

::-webkit-scrollbar {
  height: 0.5rem;
  width: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(color(mono, dark), 0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(color(mono, dark), 0.5);
  border-radius: 1rem;
}

body.mobile {
  ::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0;
  }
}
