.pagination {
  margin-bottom: 3rem;
  padding: 1rem 1.5rem;
  text-align: center;

  .pagination_previous,
  .pagination_next {
    > span,
    > a {
      background-color: color(secondary);
      border: 1px solid color(border, secondary);
      color: color(text, light);
      display: block;
      line-height: 1;
      text-align: center;
      width: 3.4rem;

      i::before {
        font-size: 2.5rem;
        line-height: 3.2rem;
      }
    }

    > span {
      opacity: 0.5;
    }

    & > a:hover {
      background-color: color(primary);
      border-color: color(primary);
    }
  }

  ul {
    display: flex;
    font-size: 0;
    text-align: center;

    li {
      display: inline-block;
      margin: 0 0.2rem;

      @include animated();

      > a,
      > span {
        display: block;
        font-size: 1.4rem;
        height: 3.4rem;
        position: relative;
        width: 3rem;

        i {
          line-height: 3.5rem;
        }

        > span {
          @extend %full-center;
        }
      }

      &.active,
      &:hover {
        > a,
        > span {
          color: color(text, secondary);
          font-weight: default(weight, bold);
        }
      }

      &:not(.active) {
        &:hover {
        }
      }
    }
  }
}

/* blog */
.p-numbers {
  margin: 0 -0.5rem;

  &-item {
    padding: 0 0.5rem;

    &.disabled {
      opacity: 0.5;
    }

    &:not(.disabled) {
      &:hover {
        .p-numbers-num {
          border-color: color(border, dark);
        }
      }
    }

    &.active {
      .p-numbers-num {
        border-color: color(border, dark);

        &::after {
          @include poa(null, 0, -1rem, 0);
          border-bottom: 1px solid color(border, dark);
          content: "";
          display: block;
        }
      }
    }
  }

  &-num {
    align-items: center;
    border: 1px solid color(border);
    display: flex;
    height: 3rem;
    justify-content: center;
    position: relative;
    transition: 0.3s;
    width: 3rem;
  }

  &-prev {
    margin-right: 1rem;
  }

  &-next {
    margin-left: 1rem;
  }
}
