/* Header */
p.header-top-message {
  background: color(mono, dark);
  color: color(mono, light);
  padding: 10px 15px;
  text-align: center;
}

.header-main {
  @include animated(box-shadow);
  background: color(secondary, base);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0);
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 210;

  &.stack {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    z-index: 300;
  }

  svg#logo_tectona {
    max-height: 33px;
    max-width: 230px;

    @media (min-width: $md-width) {
      max-height: none;
    }
  }

  .logo-desktop {
    margin-bottom: 25px;
  }

  .sticky-logo {
    display: none;
  }

  .overtop {
    height: height(banner);

    .banner-dynamic {
      position: relative;
      z-index: 1;

      + .banner-static {
        display: none;
      }
    }

    @include rem(13);

    @media (min-width: $xs-width) {
      height: height(banner, xs);
    }
    @media (min-width: $sm-width) {
      height: height(banner, sm);
    }
  }

  .header-links {
    display: none;
    width: 50%;

    @media (min-width: $sm-width) {
      display: block;
      float: left;
    }

    li {
      display: inline-block;
      padding-right: 12px;
      position: relative;
    }
  }

  .header-text {
    color: color(mono, light);
    display: block;
    text-align: center;
    width: 100%;

    @media (min-width: $sm-width) {
      float: right;
      text-align: right;
      width: 50%;
    }
  }

  .header-top-mobile {
    background: color(primary, base);
  }

  .header-ctn {
    flex-wrap: nowrap;

    @media (min-width: $md-width) {
      flex-wrap: wrap;
    }
  }

  .header-top {
    @include animated(padding);
    height: height(header);
    position: relative;

    @media (min-width: $xs-width) {
      height: height(header, xs);
    }
    @media (min-width: $sm-width) {
      height: height(header, sm);
    }
    @media (min-width: $md-width) {
      height: height(header, md);
      transition: 0.5s background;
      &:hover {
        background: color(secondary, base);
        color: color(primary, base);

        .icon-menu_mobile,
        .header-top-left-content p,
        .listing-toggle-target,
        .header-icon span {
          color: color(primary, base);
        }

        .header-icon-link .nb-product-total {
          color: color(secondary, base);
        }

        svg#logo_tectona {
          fill: color(primary, base);
          max-width: 230px;

          @media (max-width: $sm-width) {
            max-width: 100%;
          }
        }

        .advmenu-root-link {
          .has-bg-header & {
            color: color(primary);
          }
        }

        .header-pin {
          background-color: color(primary, base);
          color: color(secondary, base);
        }

        .advmenu,
        .listing-parent {
          color: color(primary, base);
        }
      }
    }
    @media (min-width: $lg-width) {
      height: height(header, lg);
    }
  }

  .header-top-left {
    display: flex;
    order: 1;
    width: 25%;

    @media (min-width: $md-width) {
      width: auto;
    }
  }

  .header-top-left-content {
    @include animated(padding);

    @media (min-width: $md-width) {
      font-size: 16px;
      justify-content: start;
    }
  }

  .header-logo {
    flex-basis: 40%;
    order: 2;
    text-align: center;

    @media (min-width: $md-width) {
      flex-basis: 100%;
      order: 3;
    }
  }

  .header-top-right {
    order: 3;
    text-align: right;
    width: 25%;

    @media (min-width: $md-width) {
      order: 2;
      width: auto;
    }

    .list-top-right {
      justify-content: flex-end;

      .icon-pointer {
        margin-right: 12px;
      }

      .header-cart {
        margin-right: 1.5rem;
      }

      @media (min-width: $md-width) {
        .header-icon {
          margin-right: 1.5rem;
        }
        .icon-pointer {
          margin-right: 0;
        }
      }
    }
  }

  .header-icon {
    cursor: pointer;
    font-size: default(font-size);
    margin: 0;
    position: relative;

    @media (min-width: $md-width) {
      margin: 0 0 0 45px;
    }
    @media (min-width: $xs-width) {
      margin: 0 0 0 10px;
    }

    [class^="icon-"],
    [class*=" icon-"] {
      font-size: 1.6rem;
    }

    &:first-child {
      margin: 0;
    }

    i {
      @include rem(22);
      color: color(primary, base);
      display: block;
      text-align: center;

      @media (min-width: $xs-width) {
        @include rem(25);
      }
      @media (min-width: $sm-width) {
        @include rem(30);
      }
    }

    .header-pin {
      @include poa(0, 0, 2rem, 1.3rem);
      background: color(primary, base);
      border-radius: 50%;
      color: color(secondary, base);
      font-size: 0.9rem;
      height: 14px;
      line-height: 14px;
      margin: auto;
      text-align: center;
      width: 14px;

      @media (min-width: $xs-width) {
        font-size: 1.1rem;
        height: 18px;
        line-height: 18px;
        width: 18px;
      }
    }

    .header-label {
      display: none;

      @media (min-width: $sm-width) {
        display: block;

        @include rem(12);
      }
    }

    @media (min-width: $md-width) {
      &:hover {
        > div,
        > a {
          position: relative;
          z-index: 1;
        }

        // &:before {
        //   background-color: color(primary, light);
        //   border-radius: 5rem;
        //   content: "";

        //   @include poa(-1.1rem, -1.1rem, -1.1rem, -1.1rem);
        // }
      }
    }
  }
}

.header-stack {
  .sticky-logo {
    @media (min-width: $md-width) {
      display: block;
      width: 52px;
      svg {
        color: color(primary, base);
        max-height: 3.5rem;
      }
    }
  }

  .logo-desktop {
    @media (min-width: $md-width) {
      display: none;
    }
  }

  .header-logo {
    margin-top: 0;
    max-width: 250px;
    order: 1;

    @media (min-width: $md-width) {
      max-width: 50px;
    }
  }

  .header-top {
    height: 6.5rem;
  }

  .header-top-left {
    @media (min-width: $md-width) {
      display: none;
    }
  }

  .advmenu {
    order: 2;
    width: 100%;

    @media (min-width: $md-width) {
      max-width: 680px;
    }
    @media (min-width: $lg-width) {
      max-width: 810px;
    }
  }

  .header-top-right {
    order: 3;
  }

  .advmenu {
    color: color(primary, base);
  }
}

/*** Custom header ***/

.desktop.has-bg-header {
  .listing-toggle-target {
    @media (min-width: $md-width) {
      color: color(mono, light);
    }
  }
}

.has-bg-header {
  .header-main {
    background: transparent;

    .icon-menu_mobile {
      color: color(secondary, base);
      font-size: 1.6rem;
    }

    svg#logo_tectona {
      fill: color(secondary, base);
    }

    .header-top-left-content p,
    .header-icon {
      color: color(secondary, base);
    }

    .header-pin {
      background: color(secondary, base);
      color: color(primary, base);
    }
  }

  &.header-stack {
    .header-main.animated {
      background: color(mono, light);
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);

      .header-pin {
        background-color: color(primary, base);
        color: color(secondary, base);
      }
    }

    .icon-menu_mobile,
    .header-top-left-content p,
    .header-icon span {
      color: color(primary, base);
    }

    svg#logo_tectona {
      fill: color(primary, base);
      max-width: 130px;

      @media (min-width: $md-width) {
        max-width: initial;
        // max-width: 200px;
      }
    }
  }
}

.menu-open-desktop {
  .breadcrumb {
    z-index: 1;
  }
}

.menu-open-desktop,
.search-open {
  .header-main {
    .icon-menu_mobile {
      color: color(primary, base);
    }

    svg#logo_tectona {
      fill: color(primary, base);
    }

    .header-top-left-content p,
    .header-icon {
      color: color(primary, base);
    }

    .header-pin {
      background: color(primary, base);
      color: color(secondary, base);
    }

    .listing-parent,
    .advmenu-root-link {
      color: color(primary, base);
    }
  }

  .header-top {
    background-color: color(mono, light);
  }
}

/**** HEADER IMG ****/

.header-img {
  &-title {
    margin: 0 auto -10px;
    position: relative;
    width: 90%;

    @media (min-width: $md-width) {
      margin-bottom: -50px;
      max-width: 100%;
      width: 90rem;
    }
    @media (min-width: $lg-width) {
      width: 107rem;
    }
  }

  h1::after {
    content: icon(carres_points);
    display: block;
    font-family: default(font-family, icon);
    font-size: 12px;
    margin-top: 1rem;
  }

  .content-head {
    font-size: 2rem;
    line-height: 1.4;
    margin: -20px auto 0;
    position: relative;
    width: 90%;

    @media (min-width: $sm-width) {
      margin-top: -4rem;
    }
    @media (min-width: $md-width) {
      font-size: 2.4rem;
      margin-top: -80px;
      max-width: 100%;
      width: 90rem;
    }
    @media (min-width: $lg-width) {
      font-size: 2.4rem;
      width: 107rem;
    }
  }
}
