.accordion-ctn-all {
  .accordion-btn {
    border-top: 1px solid color(grey, base);
    cursor: pointer;
    font-size: 1.6rem;
    margin: 0;
    padding: 1.5rem 0;
    position: relative;
    transition: 0.3s;

    @media (min-width: $md-width) {
      font-size: 1.8rem;
      margin: 0;
      padding: 3rem 0;
    }

    &:hover {
      font-weight: default(weight, bold);
    }

    &:after {
      content: "\e90c";
      font-family: "icomoon";
      font-size: 1.4rem;
      position: absolute;
      right: 0;
      transition: all 0.3s ease 0s;
    }

    &.active-accordion {
      font-weight: default(weight, bold);

      &:after {
        content: "\e909";
      }

      + .accordion-content {
        display: block;
      }
    }
  }

  .accordion-ctn {
    &:nth-last-child(1) {
      border-bottom: 1px solid color(grey, base);
    }
  }
}

.accordion-content {
  display: none;
  padding-bottom: 2rem;

  @media (min-width: $md-width) {
    padding-bottom: 4rem;
  }
}
