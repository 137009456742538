@use "sass:math";

@mixin heading(
  $font-size: default(font-size),
  $bold: true,
  $uppercase: true,
  $style: false,
  $margin: 0
) {
  @include rem($font-size);
  display: block;

  @if ($bold == true) {
    font-weight: default(weight, bold);
  }
  @if ($bold == medium) {
    font-weight: default(weight, medium);
  }
  @if ($style == true) {
    font-style: italic;
  }
  @if ($uppercase == true) {
    text-transform: uppercase;
  }
}
@mixin product-price($font-size) {
  font-size: $font-size;
  position: relative;
  .currency {
    font-size: $font-size/2 + 0.3;
    position: absolute;
    top: 0;
  }
}
@mixin rem($pixel-size: default(font-size)) {
  font-size: #{$pixel-size}px;
  font-size: #{math.div($pixel-size, 10)}rem;
}
@mixin animated($prop: all, $time: 0.3s, $easing: ease, $delay: 0s) {
  transition: $prop $time $easing $delay;
}
@mixin poa($top: null, $right: null, $bottom: null, $left: null) {
  position: absolute;

  @if ($bottom != null) {
    bottom: $bottom;
  }
  @if ($left != null) {
    left: $left;
  }
  @if ($right != null) {
    right: $right;
  }
  @if ($top != null) {
    top: $top;
  }
}
@mixin position(
  $type: "absolute",
  $top: null,
  $right: null,
  $bottom: null,
  $left: null
) {
  position: $type;

  @if ($bottom != null) {
    bottom: $bottom;
  }
  @if ($left != null) {
    left: $left;
  }
  @if ($right != null) {
    right: $right;
  }
  @if ($top != null) {
    top: $top;
  }
}
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}
@mixin padding($top: null, $right: null, $bottom: null, $left: null) {
  @if ($bottom != null) {
    padding-bottom: $bottom;
  }
  @if ($left != null) {
    padding-left: $left;
  }
  @if ($right != null) {
    padding-right: $right;
  }
  @if ($top != null) {
    padding-top: $top;
  }
}
@mixin size($width, $height: $width) {
  height: #{$height};
  width: #{$width};
}
// size without unit
@mixin sizePx($width, $height: $width) {
  height: #{$height}px;
  width: #{$width}px;
}
@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}

/* Compatibilité Chrome, Firefox, pour le :-internal */
@mixin label-base {
  font-weight: normal;
}
@mixin label-animated {
  background-color: color(background, light);
  color: color(primary, base);
  font-size: 1.1rem;
  font-weight: default(weight, medium);
  line-height: 1;
  margin-bottom: 0;
  margin-left: -0.3rem;
  padding: 0 0.3rem;
  top: -0.5rem;
  transform: translateY(0);
}
@mixin text-color($parent, $color, $ignore-warning: false) {
  #{$parent} {
    color: $color;
  }
}

/* background colors (bootstrap is _background-variant) */
@mixin bg-color($parent, $color, $ignore-warning: false) {
  #{$parent} {
    background-color: $color;
  }
}
@mixin custom-checkbox-radio {
  display: flex;
  flex-basis: 100%;
  position: relative;

  label {
    cursor: pointer;
    font-size: 12px;

    @media (min-width: $md-width) {
      font-size: 14px;
    }

    .link {
      font-size: inherit;
      line-height: 1;
    }
  }

  input {
    cursor: pointer;
    height: 40px;
    left: -1rem;
    margin: 0;
    opacity: 0;
    padding: 2rem;
    position: absolute;
    top: -1rem;
    width: 40px;
    z-index: 3;
  }

  &::before {
    content: "";
    cursor: pointer;
    display: block;
    flex-shrink: 0;
    font-family: icomoon;
    font-size: height(form, radio);
    left: 0;
    line-height: 1;
    margin-right: 10px;
    position: relative;
    top: 0;
  }

  &.checked {
    label {
      font-weight: default(weight, medium);
    }
  }
}
@mixin txt-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:placeholder {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
