html {
  font-family: default(font-family);
  font-size: 62.5%;
  overflow-x: hidden;
  text-size-adjust: 100%;
  width: 100vw;
}

body {
  display: flex;
  flex-direction: column;
  font-size: default(font-size);
  line-height: default(line-height);
  min-height: 100vh;
  overflow-x: hidden;
}

.position-relative {
  position: relative;
}

.d-none {
  display: none;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.overflow-horizontal {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 1rem;
  white-space: nowrap;
}

/* Site content */
.site-content {
  @include animated(padding);
  padding-top: height(header);

  @media (min-width: $xs-width) {
    padding-top: height(header, xs);
  }
  @media (min-width: $sm-width) {
    padding-top: height(header, sm);
  }
  @media (min-width: $md-width) {
    padding-top: height(header, md);
  }
  @media (min-width: $lg-width) {
    padding-top: height(header, lg);
  }
}

.has-bg-header .site-content {
  padding-top: 0;
}

/* breadcrumb */
.has-bg-header {
  .breadcrumb {
    @include poa(auto, 0, auto, 0);
    color: color(secondary);
    top: height(header);

    @media (min-width: $xs-width) {
      top: height(header, xs);
    }
    @media (min-width: $sm-width) {
      top: height(header, sm);
    }
    @media (min-width: $md-width) {
      top: height(header, md);
    }
    @media (min-width: $lg-width) {
      top: height(header, lg);
    }
  }

  .offset-block {
    position: relative;
    z-index: 1;
  }
}

/* Header-stck */
/* .header-stack {
  .site-content {
    padding-top: height(header, stack);
  }
} */

/**** Gototop ***/

.gototop {
  bottom: 100px;
  cursor: pointer;
  font-size: 9px;
  opacity: 0;
  position: fixed;
  right: 15px;
  transition: 0.3s opacity;
  visibility: hidden;
  z-index: 100;

  @media (min-width: $md-width) {
    font-size: 1.2rem;
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }

  span {
    padding-top: 20px;
  }

  &:after {
    bottom: 12px;
    content: "\e91c";
    font-family: "icomoon";
    font-size: 22px;
    left: 0;
    position: absolute;
    transform: rotate(90deg);
    transition: 0.3s;

    @media (min-width: $md-width) {
      bottom: 18px;
    }
  }

  &:hover {
    &:after {
      bottom: 15px;
      font-size: 30px;
      left: -4px;
      position: absolute;
      transform: rotate(90deg);
      transition: 0.3s;

      @media (min-width: $md-width) {
        bottom: 20px;
      }
    }
  }
}
