.h1,
.wysiwyg h1 {
  @include heading(25, true, false);
  font-family: default(font-family, secondary);
  letter-spacing: 0.1rem;
  line-height: 3.5rem;

  @media (min-width: $xs-width) {
    font-size: 3rem;
  }
  @media (min-width: $sm-width) {
    font-size: 3.5rem;
  }
  @media (min-width: $md-width) {
    font-size: 4rem;
    // letter-spacing: 0.4rem;
    line-height: 5.5rem;
  }
  @media (min-width: $lg-width) {
    font-size: 4.2rem;
  }
  @media (min-width: $xl-width) {
    font-size: 4.2rem;
  }
}

.h2,
.wysiwyg h2 {
  @include heading(18, true, false);
  font-family: default(font-family, secondary);
  letter-spacing: 0.72px;
  line-height: 2.2rem;

  @media (min-width: $xs-width) {
    @include rem(18);
  }
  @media (min-width: $sm-width) {
    @include rem(18);
  }
  @media (min-width: $md-width) {
    @include rem(28);
    letter-spacing: 1.12px;
    line-height: 3.2rem;
  }
  @media (min-width: $lg-width) {
    @include rem(28);
  }
}

.h2-listing {
  @include heading(16, true, false);
  font-family: default(font-family, secondary);
  letter-spacing: 0.1rem;
  line-height: 2rem;

  @media (min-width: $xs-width) {
    @include rem(20);
  }
  @media (min-width: $sm-width) {
    @include rem(25);
  }
  @media (min-width: $md-width) {
    @include rem(28);
    letter-spacing: 0.15rem;
    line-height: 3.2rem;
  }
  @media (min-width: $lg-width) {
    @include rem(28);
  }
}

.h3 {
  @include heading(16, true, false);
  font-family: default(font-family, secondary);
  letter-spacing: 0.1rem;
  line-height: 2.2rem;

  @media (min-width: $sm-width) {
    @include rem(20);
  }
  @media (min-width: $md-width) {
    @include rem(22);
    // letter-spacing: 0.4rem;
    line-height: 3rem;
  }
  @media (min-width: $lg-width) {
    @include rem(22);
  }
}

.h4,
.wysiwyg h3 {
  @include heading(20, false, false);
  line-height: 2.6rem;

  @media (min-width: $md-width) {
    @include rem(24);
    line-height: 3.2rem;
  }
}

.h5 {
  @include heading(16, true, false);
}
.baseline {
  letter-spacing: 0.3rem;
  text-transform: uppercase;
}

.title-bordered {
  overflow: hidden;
  span {
    display: inline-block;
    position: relative;
    &:before,
    &:after {
      @include poa(0, 0, 0, 0);
      border-top: 1px solid color(primary);
      content: "";
      display: block;
      height: 1px;
      margin: auto;
    }
    &:before {
      left: calc(100% + 15px);
      right: -1000px;
    }
    &:after {
      left: -1000px;
      right: calc(100% + 15px);
    }
  }
}

.home-h1 {
  margin: auto;
  margin-top: 80px;
  max-width: 1070px;
  padding: 0 15px;
  position: relative;

  @media (min-width: $md-width) {
    margin-top: 125px;
  }
  &:before {
    content: "";
    font-family: "icomoon";
    font-size: 12px;
    left: 50%;
    position: absolute;
    top: -35px;
    transform: translateX(-50%);

    @media (min-width: $md-width) {
      top: -55px;
    }
  }

  &:after {
    bottom: -37px;
    content: "";
    font-family: "icomoon";
    font-size: 12px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);

    @media (min-width: $md-width) {
      bottom: -55px;
    }
  }
}
